import React from "react"
// import Layout from "../components/Layout"
// import { Link } from "gatsby"
// import SEO from "../components/SEO"

const Error = () => {
  return <h2>error page</h2>
}

export default Error
